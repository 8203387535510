@import "./assets/styles/fonts.css";
@import "./assets/styles/paymentfont.min.css";
html,
body {
  height: 100%;
  font-family: "AvenirNextLTPro-Regular";
}

html {
  font-size: 62.5%;
}

body {
  background-color: #f3f3f3;
  margin: 0;
  padding: 0;
  position: relative;
  font-size: 15px;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1em;
}

label,
.help-block {
  color: #505050;
  font-size: 15px;
}

input[type="radio"] {
  margin-top: 2px;
}

.radio {
  margin-top: 0;
}

.form-control,
.input-group-addon {
  border: 1px solid #808080;
  height: 36px;
}

.input-group-addon {
  color: #505050;
}

input[type="radio"],
input[type="checkbox"] {
  margin-top: 2px;
}

:focus {
  box-shadow: none;
  outline: unset;
}

.required {
  color: red;
}

.HomeMenuItem {
  height: 50px;
}

.HomeMenuItem .primary {
  font-size: 15px;
}

.hoverParent:hover .hoverChildren {
  visibility: visible;
}

.hoverParent:hover .hoverFont {
  color: #007aff;
}

.MuiDialogTitle-root > h6 {
  font-size: 17px;
  font-family: AvenirNextLTPro-Bold;
}

.MuiDialog-paperWidthLg > .MuiDialogActions-root {
  border-top: 1px solid #d8d8d8;
}

.MuiDialog-paperWidthLg > .MuiDialogTitle-root {
  border-bottom: 1px solid #d8d8d8;
}

.MuiTab-textColorInherit.Mui-selected {
  color: #007aff;
  font-weight: 600;
  font-family: "AvenirNextLTPro-Demi";
}

.Mui-selected > .listItemMenuPrimary {
  color: #007aff;
}

.MuiInputAdornment-filled.MuiInputAdornment-positionStart {
  margin-top: 14px !important;
}

.MuiTabs-scrollable,
.MuiTabs-fixed {
  margin-bottom: -1px !important;
}

.MuiAvatar-colorDefault {
  padding-top: 2px;
}

.onlyRead > .MuiFilledInput-root {
  background-color: #ffffff;
}

.onlyRead > .MuiFilledInput-root:hover {
  background-color: #ffffff;
}

.tableCustompayDeduc:hover .tableTd > div {
  background-color: rgba(0, 0, 0, 0.1);
}

.tableCustompayDeduc .error {
  height: 30px !important;
}

.onlyRead > .MuiFilledInput-root:hover {
  background-color: #ffffff;
}

.pf {
  color: white;
  background: #7d848b;
  width: 50px;
  height: 35px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: flex;
  border-radius: 5px;
}

.rigthColumn {
  text-align: center;
  align-items: center;
  display: inline-flex;
}

.rigthColumnImg {
  margin-left: 25px;
  width: 90%;
}

.plnCardPriceOff::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 1px;
  background-color: red;
  transform: rotate(-25deg);
  width: 110px;
  height: 3px;
  top: 20px;
  left: 50px;
}

.slick-dots {
  bottom: auto !important;
  width: auto !important;
  top: 321px;
  right: 100px;
  z-index: 11;
}
.slick-dots-last {
  bottom: auto !important;
  width: auto !important;
  top: 250px;
  right: 100px;
}
.slick-prev,
.slick-next {
  z-index: 999;
}

.slick-next {
  right: 25px;
  top: 330px;
}

.slick-prev {
  transform: rotate(180deg);
  right: 300px;
  left: auto;
  top: 322px;
}

.slick-prev:before,
.slick-prev:after,
.slick-next:after,
.slick-next:before {
  border-right: 2px solid;
  content: "";
  display: block;
  height: 8px;
  margin-top: -6px;
  position: absolute;
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 10px;
  top: 50%;
  width: 0;
  color: #f02f0f;
}

.slick-prev:after,
.slick-next:after {
  margin-top: -1px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.slick-dots li.slick-active button:before {
  color: #f02f0f;
  opacity: 1 !important;
}

.slick-dots li.slick-active {
  color: #f02f0f !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #f02f0f;
}

.slick-dots li button:hover:before {
  opacity: 1 !important;
}
.slick-dots li button:focus:before {
  opacity: 0.25;
}

.slick-dots li {
  margin: 0 2px;
}

.slick-dots li {
  cursor: pointer;
}

.slick-dots-quickSetup .qs-noHover:not(:first-child) button:hover:before,
.slick-dots-quickSetup .qs-noHover:not(:first-child) button:focus:before {
  opacity: 0.25 !important;
  pointer-events: none;
  cursor: not-allowed;
}

.slick-current {
  z-index: 10 !important;
}

.slick-dots-quickSetup {
  top: 260px !important;
}
.slick-dots-businessInfo{
  position: unset !important;
  height: 10px;
}

@font-face {
  font-family: 'AvenirNextLTPro-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/AvenirNextLTPro-Regular.eot');
  src: url('../fonts/AvenirNextLTPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AvenirNextLTPro-Regular.otf') format('opentype'),
    url('../fonts/AvenirNextLTPro-Regular.svg') format('svg'),
    url('../fonts/AvenirNextLTPro-Regular.ttf') format('truetype'),
    url('../fonts/AvenirNextLTPro-Regular.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/AvenirNextLTPro-Bold.eot');
  src: url('../fonts/AvenirNextLTPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AvenirNextLTPro-Bold.otf') format('opentype'),
    url('../fonts/AvenirNextLTPro-Bold.svg') format('svg'),
    url('../fonts/AvenirNextLTPro-Bold.ttf') format('truetype'),
    url('../fonts/AvenirNextLTPro-Bold.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'AvenirNextLTPro-DemiCn';
  src: url('../fonts/AvenirNextLTPro-DemiCn.eot');
  src: url('../fonts/AvenirNextLTPro-DemiCn.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AvenirNextLTPro-DemiCn.otf') format('opentype'),
    url('../fonts/AvenirNextLTPro-DemiCn.svg') format('svg'),
    url('../fonts/AvenirNextLTPro-DemiCn.ttf') format('truetype'),
    url('../fonts/AvenirNextLTPro-DemiCn.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-DemiCn.woff2') format('woff2');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Medium';
  src: url('../fonts/AvenirNextLTPro-Medium.otf') format('opentype');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-MediumCn';
  src: url('../fonts/AvenirNextLTPro-MediumCn.eot');
  src: url('../fonts/AvenirNextLTPro-MediumCn.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AvenirNextLTPro-MediumCn.otf') format('opentype'),
    url('../fonts/AvenirNextLTPro-MediumCn.svg') format('svg'),
    url('../fonts/AvenirNextLTPro-MediumCn.ttf') format('truetype'),
    url('../fonts/AvenirNextLTPro-MediumCn.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-MediumCn.woff2') format('woff2');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url('../fonts/AvenirNextLTPro-Demi.eot');
  src: url('../fonts/AvenirNextLTPro-Demi.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AvenirNextLTPro-Demi.otf') format('opentype'),
    url('../fonts/AvenirNextLTPro-Demi.svg') format('svg'),
    url('../fonts/AvenirNextLTPro-Demi.ttf') format('truetype'),
    url('../fonts/AvenirNextLTPro-Demi.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-Demi.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-HeavyCn';
  src: url('../fonts/AvenirNextLTPro-HeavyCn.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Cn';
  src: url('../fonts/AvenirNextLTPro-Cn.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-DemiIt';
  src: url('../fonts/AvenirNextLTPro-DemiIt.otf');
  font-weight: normal;
  font-style: normal;
}
